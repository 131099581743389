import { ProjectEvaluateAreaProps } from 'components/organisms/projectEvaluateArea';
import {
  ShifterProjectsJpDataAcf,
  Maybe,
  ShifterProjectsJpDataAcfAwards,
  ShifterProjectsJpDataAcfAbstruct,
  ShifterProjectsJpDataAcfHighlights,
  ShifterProjectsJpDataAcfProcess,
  ShifterProjectsDataAcfModulesFeature,
  ShifterProjectsJpDataAcfModulesFeature,
  ShifterProjectsJpData,
  ShifterDataProjects_JpAcfLink_Area,
} from 'types/graphql-types';

export const isAvailableAcf = (acf: any): acf is ShifterProjectsJpDataAcf => {
  return acf !== undefined && acf !== null && typeof acf === 'object';
};

export const isAvailableAwards = (acf: any): acf is { awards: Array<Maybe<ShifterProjectsJpDataAcfAwards>> } => {
  if (!isAvailableAcf(acf)) return false;
  return Array.isArray(acf.awards);
};

export const isShifterProjectsJpData = (data: any): data is ShifterProjectsJpData => {
  return typeof data === 'object' && data.shifterProjectsJpData !== undefined && data.shifterProjectsJpData !== null;
};

export const isAvailableAbstruct = (acf: any): acf is { abstruct: ShifterProjectsJpDataAcfAbstruct } => {
  if (!isAvailableAcf(acf)) return false;
  return acf.abstruct !== undefined && acf.abstruct !== null && Array.isArray(acf.abstruct.contents);
};

export const isAvailableHighlightsArea = (acf: any): acf is { highlights: ShifterProjectsJpDataAcfHighlights } => {
  if (!isAvailableAcf(acf)) return false;
  return acf.highlights !== undefined && acf.highlights !== null && Array.isArray(acf.highlights.contents);
};

export function isProjectEvaluateAreaContents(contents: any): contents is ProjectEvaluateAreaProps['contents'] {
  return (
    Array.isArray(contents) &&
    contents.every(content => {
      return typeof content === 'object' && content !== null && 'type' in content;
    })
  );
}

export function isAvailableLinkArea(content: any): content is Array<ShifterDataProjects_JpAcfLink_Area> {
  return (
    content !== undefined && content !== null && Array.isArray(content) && Array.isArray(content[0].links.link_list)
  );
}

export const isAvailableProcessJa = (acf: any): acf is { process: Array<Maybe<ShifterProjectsJpDataAcfProcess>> } => {
  if (!isAvailableAcf(acf)) return false;
  return (
    acf.process !== undefined && acf.process !== null && Array.isArray(acf.process.ja) && !!acf.process.ja[0]?.text
  );
};

export const isAvailableProcessEn = (acf: any): acf is { process: Array<Maybe<ShifterProjectsJpDataAcfProcess>> } => {
  if (!isAvailableAcf(acf)) return false;
  return (
    acf.process !== undefined && acf.process !== null && Array.isArray(acf.process.en) && !!acf.process.en[0]?.text
  );
};

export const isShifterProjectsDataAcfModulesFeature = (
  feature: any
): feature is ShifterProjectsDataAcfModulesFeature => {
  return feature !== undefined && feature !== null && typeof feature === 'object' && 'pics' in feature;
};

export const isShifterProjectsJpDataAcfModulesFeature = (
  feature: any
): feature is ShifterProjectsJpDataAcfModulesFeature => {
  return feature !== undefined && feature !== null && typeof feature === 'object' && 'sub_contents' in feature;
};
