import styled from '@emotion/styled';
import { Button } from 'components/atoms/button';
import { EnHeading } from 'components/atoms/enHeading';
import { ContentWrapper } from 'components/organisms/contentWrapper';
import React, { useEffect } from 'react';
import { TextSet } from 'components/molecules/textSet/index';
import { JaHeading } from 'components/atoms/jaHeading';
import { Text } from 'components/atoms/text/index';
import { ContentInner } from 'components/organisms/contentInner/index';
import ParticleImageManager from 'components/organisms/particleCanvas/ParticleImageManager';
import sizes from 'style/sizes';
import { mq } from 'style/variables';
import { graphql } from 'gatsby';
import SEO from 'util/seo';
import FadeUpSection from 'components/organisms/fadeUpSection';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

const TitleSection = styled('div')`
  width: 100%;
  ${mq.onlypc} {
    padding-top: ${sizes.margin[180]};
    padding-bottom: ${sizes.margin[100]};
  }
  ${mq.onlysp} {
    padding-top: ${sizes.margin[155]};
    padding-bottom: ${sizes.margin[64]};
  }
`;

const ContactWrap = styled.div`
  padding-bottom: ${sizes.margin[180]};
  ${mq.onlysp} {
    padding-bottom: ${sizes.margin[120]};
  }
`;
const ContactItem = styled.div`
  &:not(:last-of-type) {
    padding-bottom: ${sizes.margin[180]};
  }
  ${mq.onlysp} {
    &:not(:last-of-type) {
      padding-bottom: ${sizes.margin[120]};
    }
  }
`;
const ContactTexts = styled(TextSet)`
  margin-bottom: 0;
  ${mq.onlysp} {
    margin-bottom: 0;
  }
  * + & {
    margin-top: ${sizes.margin[48]};
  }
  p + p {
    ${mq.onlysp} {
      margin-top: ${sizes.margin[48]};
    }
  }
  * + a {
    margin-top: 24px;
    ${mq.onlysp} {
      width: 251px;
      height: 47px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
const ContactButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: ${sizes.margin[56]};
  ${mq.onlysp} {
    margin-top: ${sizes.margin[44]};
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  > a {
    ${mq.onlysp} {
      width: 251px;
      height: 47px;
    }
    & + a {
      ${mq.onlypc} {
        margin-left: 15px;
      }
      ${mq.onlysp} {
        margin-top: 15px;
      }
    }
  }
`;

const Pages: React.FC<IndexPageProps> = () => {
  useEffect(() => {
    ParticleImageManager.loadAll().then(() => {
      ParticleImageManager.generateAll().then(() => {
        ParticleImageManager.start();
      });
    });

    return () => {
      ParticleImageManager.delete();
    };
  }, []);

  return (
    <>
      <SEO title={'Contact'} />
      <ContentWrapper>
        <TitleSection>
          <EnHeading>Contact</EnHeading>
        </TitleSection>
        <ContentInner>
          <ContactWrap>
            <ContactItem>
              <FadeUpSection>
                <ContactTexts>
                  <JaHeading>Requests or Inquiries</JaHeading>
                  <Text>
                    Please contact us for requests and consultations on business design, CX improvement, MVV design, EX
                    (Employee Experience) design and branding. Feel free to contact us even if you are in the initial
                    conception stage, with nothing specific decided yet.
                  </Text>
                </ContactTexts>
                <ContactButtons>
                  <Button href={'https://4dsd.svy.ooo/ng/answers/28a8fd4eb5272b60eb8054b6ad2ce6/'} blank type={'fill'}>
                    Contact
                  </Button>
                </ContactButtons>
              </FadeUpSection>
            </ContactItem>

            <ContactItem>
              <FadeUpSection>
                <ContactTexts>
                  <JaHeading>Recruitment</JaHeading>
                  <Text>
                    We are looking for people who share the same thoughts and approach in design in any positions, from
                    UX designers to designers, engineers and administrators.
                  </Text>
                  <ContactButtons>
                    <Button href={'https://recruit.4digit.com/'} blank>
                      Recruit Site
                    </Button>
                  </ContactButtons>
                </ContactTexts>
                <ContactTexts>
                  <Text>
                    If you want to apply for a job directly or send us questions, please contact us using this form. You
                    can also contact us through a recruitment website.
                  </Text>
                  <ContactButtons>
                    <Button
                      href={'https://4dsd.svy.ooo/ng/answers/1b39d8912959a09c58ea1ba9456b1e/'}
                      blank
                      type={'fill'}
                    >
                      Contact
                    </Button>
                  </ContactButtons>
                </ContactTexts>
              </FadeUpSection>
            </ContactItem>

            <ContactItem>
              <FadeUpSection>
                <ContactTexts>
                  <JaHeading>Inquiries about Release and Publicity</JaHeading>
                  <Text>
                    Please contact us here for any inquiries regarding releases, interviews or office space usage
                    requests. You may also share any other opinions about the company or the team here.
                  </Text>
                </ContactTexts>
                <ContactButtons>
                  <Button href={'https://4dsd.svy.ooo/ng/answers/5712ccdddf205fb616b9d0107242a0/'} blank type={'fill'}>
                    Contact
                  </Button>
                </ContactButtons>
              </FadeUpSection>
            </ContactItem>
          </ContactWrap>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Pages;

export const pageQuery = graphql`
  query ContactIndexEn {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
